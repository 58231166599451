import React, {useState} from "react";
import { axiosWithAuth } from "../utils/axiosWithAuth";
import styled from "styled-components";

let Estimate = props =>{

    console.log(props.props)

    const [state, setState] = useState({estimate: ''})

   let longevity = e => {
        e.preventDefault();
        axiosWithAuth()
          .post(`https://nba-project.herokuapp.com/api`,
            props.props
          )
          .then(res => {
            console.log("hello");
            console.log(res.data);
            setState({estimate: res.data.results.y_pred})
          })
          .catch(err => console.log("DSssss",err));
      };

    console.log("Estimate", props)
    return(
        <div>
            <Btn onClick={longevity}>Career Expected Longevity</Btn>
            {state.estimate && <P>{state.estimate}</P>}
        </div>

    )
};

export default Estimate;

const Btn = styled.button`
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 4px;
`;

const P = styled.p`
    font-size: 5rem;
`;